import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslate } from 'react-translate';
import { IconButton } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import isCyrillic from 'helpers/isCyrillic';
import { styled } from '@mui/system';
import renderHtml from 'helpers/renderHtml';
import { ReactComponent as CloseIcon } from 'assets/icons/daily/fluent-emoji-high-contrast_cross-mark.svg';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertStyled = styled(Alert)(({ severity }) => ({
  borderRadius: '15px',
  position: 'relative',
  marginBottom: 22,
  boxShadow: 'none',
  '& .MuiAlert-action': {
    position: 'absolute',
    left: -12,
    top: -12,
    padding: 0,
    margin: 0,
    borderRadius: '50%',
    backgroundColor: '#D9D9D9',
    '& button': {
      padding: 5,
      margin: 0
    }
  },
  ...(severity === 'dailyQuest'
    ? {
        backgroundColor: '#FBFFC3'
      }
    : {})
}));

const ContentWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 10
});

const ErrorSnackbar = ({
  closeDelay = 5000,
  error: { message, variant, icon, details, autoClose = true },
  onClose
}) => {
  const t = useTranslate(variant === 'dailyQuest' ? 'DAILY' : 'ERRORS');
  const errors = useSelector((state) => state?.error?.list || []);

  const handleClose = React.useCallback((e) => onClose(e), [onClose]);

  React.useEffect(() => {
    if (!autoClose) return;

    setTimeout(() => {
      if (!errors.length) return;

      if (!['error', 'dailyQuest'].includes(variant)) {
        onClose();
      }
    }, closeDelay);
  }, [autoClose, errors, closeDelay, onClose, variant]);

  const translateDetails = React.useCallback(
    (details) => {
      if (!details) return {};

      return Object.keys(details).reduce((acc, key) => {
        if (isCyrillic(details[key]) && typeof details[key] !== 'string') {
          acc[key] = details[key];
          return acc;
        }
        acc[key] = t(details[key]);
        return acc;
      }, {});
    },
    [t]
  );

  return (
    <AlertStyled
      icon={false}
      severity={variant || 'error'}
      variant="filled"
      action={
        <IconButton size="small" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      }>
      <ContentWrapper>
        {icon ? <span>{icon}</span> : null}
        <span>
          {renderHtml(isCyrillic(message) ? message : t(message, translateDetails(details)))}
        </span>
      </ContentWrapper>
    </AlertStyled>
  );
};

export default ErrorSnackbar;
