/* eslint-disable eqeqeq */
import React from 'react';
import { IS_SNAP } from 'variables/main';

const GoogleServices = () => {
  React.useEffect(() => {
    const initGoogleAds = () => {
      const script = document.createElement('script');
      script.async = true;
      script.src =
        'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2026990955557143';
      script.crossOrigin = 'anonymous';
      document.head.appendChild(script);
    };

    const initGoogleTag = () => {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-PD82NB2P');
    };

    if (IS_SNAP) return;

    initGoogleAds();
    initGoogleTag();
  }, []);

  return null;
};

export default GoogleServices;
