import * as api from 'services/api';
import storage from 'helpers/storage';
import { signOut, auth } from 'services/firebase';

export const ping = () => (dispatch) => api.get('auth', 'PING', dispatch);

export const setUser = (user) => {
  return { type: 'SET_USER', payload: user };
};

export const logout = () => {
  signOut(auth);
  storage.removeItem('token');
  return { type: 'LOGOUT' };
};
