import qs from 'qs';
import { toUnderscoreObject } from 'helpers/toUnderscore';
import dotToPath from 'helpers/dotToPath';
import cleanDeep from 'clean-deep';
import { getLanguage } from 'translation';
import { IS_SNAP, SNAP_SHOT_LIMIT } from 'variables/main';

const professionsEndpoint = {
  dataURL: 'professions',
  sourceName: 'professionsList',
  autoLoad: true,
  getDataUrl: (
    url,
    { page, perPage, filters, filters: { id, name, ...filtersRest }, sort, search, data }
  ) => {
    const urlData = {
      filters: toUnderscoreObject(dotToPath({ ...filtersRest, search: name }), false),
      sort
    };

    if (search) {
      urlData.search = search;
    }

    const exclude_ids = !!!Object.keys(cleanDeep(filters)).length;

    if (exclude_ids && data && !Object.keys(sort).length) {
      urlData.exclude_ids = data.map(({ _id }) => _id);
    } else {
      const offset = ((page || 1) - 1) * perPage;

      urlData.offset = offset;

      urlData.limit = IS_SNAP ? SNAP_SHOT_LIMIT : perPage;
    }

    if (id) {
      urlData.id = id;
    }

    const lang = getLanguage();

    urlData.fields = ['_id', `descriptionShort${lang}`, `name${lang}`, 'tags', 'photoLinks'];

    const queryString = qs.stringify(cleanDeep(urlData, { NaNValues: true }));

    return url + (queryString && '?' + queryString);
  },
  mapData: (payload, state, concat) => {
    const { meta } = payload;
    const { limit, count, offset } = meta || {};

    return {
      data: concat ? (state.data || []).concat(payload) : payload,
      page: Math.ceil(Number(offset) / Number(limit)) + 1,
      perPage: limit,
      count
    };
  }
};

export default professionsEndpoint;
