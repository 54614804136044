const GET_USER_RESOURCES = 'GET_USER_RESOURCES';
const GET_DAILY_QUEST_SUCCESS = 'GET_DAILY_QUEST_SUCCESS';
const GET_GAMIFICATION_DATA_SUCCESS = 'GET_GAMIFICATION_DATA_SUCCESS';

const initialState = {
  resources: {},
  balance: {},
  dailyQuest: {}
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DAILY_QUEST_SUCCESS:
      return { ...state, dailyQuest: action.payload };
    case GET_USER_RESOURCES:
      return { ...state, resources: action.payload };
    case GET_GAMIFICATION_DATA_SUCCESS:
      return { ...state, balance: action.payload };
    default:
      return state;
  }
};

export default rootReducer;
