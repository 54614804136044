import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from 'components/Snackbars/Snackbar';
import Box from '@mui/material/Box';
import { closeError } from 'actions/error';
import { styled } from '@mui/system';

const BoxStyled = styled(Box)(({ mobile }) => ({
  borderRadius: 0,
  right: 22,
  bottom: 22,
  position: 'fixed',
  zIndex: 5000,
  maxWidth: 350,
  width: '100%',
  ...((mobile === 'true' && {
    bottom: 'unset',
    top: 22
  }) ||
    {})
}));

const SnackbarWrapper = () => {
  const dispatch = useDispatch();
  const errors = useSelector((state) => state?.error?.list || []);
  const isMobile = useSelector((state) => state.app.isMobile);

  const handleClose = React.useCallback(
    (errorIndex) => dispatch(closeError(errorIndex)),
    [dispatch]
  );

  return (
    <BoxStyled mobile={`${isMobile}`}>
      {(errors || []).map((error, index) => (
        <Snackbar key={index} error={error} onClose={(e) => handleClose(index)} />
      ))}
    </BoxStyled>
  );
};

export default SnackbarWrapper;
