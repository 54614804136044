import React from 'react';
import { setUser } from 'actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { subscribe } from 'services/firebase';
import Analytics from 'services/analytics';
import { getDailyQuest, triggerDailyQuest, getGamificationData } from 'actions/gamification';
import { ping } from 'actions/auth';
import { DAILY } from 'variables/main';

const Auth = ({ children }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.app.user);

  React.useEffect(() => Analytics.init(), []);

  React.useEffect(() => {
    subscribe((user) => dispatch(setUser(user)));
  }, [dispatch]);

  React.useEffect(() => {
    const fetchGamificationData = async () => {
      await dispatch(triggerDailyQuest(DAILY.init));
      if (!user) return;
      await dispatch(ping());
      await dispatch(getGamificationData());
      await dispatch(getDailyQuest());
    };

    fetchGamificationData();
  }, [dispatch, user]);

  return children;
};

export default Auth;
