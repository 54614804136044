const IS_SNAP = /ReactSnap/.test(navigator.userAgent);
const SNAP_SHOT_LIMIT = 500;
const PER_PAGE_MAX_LIMIT = 100;
const WORDS_PER_MINUTE = 200;
const CHECK_PAYMENT_INTERVAl = 10000;
const COLUMNS_LIMIT_DEFAULT = 6;
const COLUMNS_LIMIT = 3;
const MAX_MESSAGE_LIMIT = 5000;
const BREADCRUMBS_LENGTH = 70;
const HIGHT_DEGREE = ['Заклад вищої освіти'];
const PROFESSIONAL = [
  'Інший заклад освіти, що надає професійну (професійно-технічну освіту)',
  'Заклад професійної (професійно-технічної) освіти'
];
const PROFESSIONAL_BEFORE = ['Заклади фахової передвищої освіти'];

const STATUSES = (theme) => ({
  created: theme.palette.textBlack.main,
  processing: theme.palette.textBlack.main,
  hold: theme.palette.textBlack.main,
  success: theme.palette.primary.main,
  failure: theme.palette.error.main,
  reversed: theme.palette.error.main,
  expired: theme.palette.error.main
});

const DAILY = {
  init: 'init',
  login: 'login',
  readArticle: 'readArticle',
  readProfession: 'readProfession',
  goToJobVacancy: 'goToJobVacancy',
  readEducationalProgram: 'readEducationalProgram',
  leaveReview: 'addQuestionOrReview',
  answerQuestion: 'answerQuestion'
};

export {
  IS_SNAP,
  SNAP_SHOT_LIMIT,
  PER_PAGE_MAX_LIMIT,
  WORDS_PER_MINUTE,
  CHECK_PAYMENT_INTERVAl,
  COLUMNS_LIMIT,
  COLUMNS_LIMIT_DEFAULT,
  STATUSES,
  MAX_MESSAGE_LIMIT,
  HIGHT_DEGREE,
  PROFESSIONAL,
  PROFESSIONAL_BEFORE,
  DAILY,
  BREADCRUMBS_LENGTH
};
