import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgba(121, 201, 18, 1)'
    },
    secondary: {
      main: '#19857b'
    },
    error: {
      main: '#EE0004'
    },
    textBlack: {
      main: 'rgba(34, 34, 34, 1)'
    },
    textGray: {
      main: 'linear-gradient(120deg, #f093fb 0%, #ebedee 100%)'
    },
    textWhite: {
      main: '#FFFFFF'
    }
  },
  textCut: (lines) => ({
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    WebkitLineClamp: lines
  }),
  wrapper: {
    maxWidth: 1440,
    margin: '0 auto'
  },
  components: {
    MuiStack: {
      styleOverrides: {
        root: {
          marginBottom: 10,
          gap: 5,
          flexWrap: 'wrap'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          display: 'flex',
          alignItems: 'center',
          '& svg': {
            marginRight: 5
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 10,
          '& .MuiOutlinedInput-root': {
            borderRadius: 10,
            border: '1px solid #BDBDBD',
            '&.Mui-error': {
              border: '1px solid #EE0004'
            }
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          border: '1px solid rgba(185, 248, 51, 1)',
          color: 'rgba(34, 34, 34, 1)',
          backgroundColor: '#fff',
          height: 28,
          '&:hover': {
            backgroundColor: 'rgba(185, 248, 51, 1)',
            color: 'rgba(34, 34, 34, 1)'
          }
        },
        label: {
          fontWeight: 500,
          fontSize: 12,
          lineHeight: '12px'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          textTransform: 'none',
          fontFamily: 'Rubik-Regular',
          fontSize: 18,
          lineHeight: '22px',
          fontWeight: 500,
          '&:hover': {
            backgroundColor: 'rgba(121, 201, 18, 1)',
            color: 'rgba(34, 34, 34, 1)'
          }
        },
        containedPrimary: {
          backgroundColor: 'rgba(185, 248, 51, 1)',
          color: 'rgba(34, 34, 34, 1)',
          fontFamily: 'Rubik-Regular',
          fontSize: 18,
          lineHeight: '22px',
          fontWeight: 500,
          textTransform: 'none',
          padding: '14px 33px',
          borderRadius: 0,
          '&:hover': {
            backgroundColor: 'rgba(121, 201, 18, 1)'
          }
        },
        outlinedPrimary: {
          padding: '12px 32px'
        },
        sizeSmall: {
          padding: '8px 16px',
          fontSize: 14
        }
      }
    },
    MuiPopper: {
      styleOverrides: {
        paper: {
          fontFamily: 'Rubik-Regular',
          fontSize: 14
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Rubik-Regular'
        },
        h1: {
          fontSize: 36,
          fontWeight: 600,
          fontFamily: 'Unbounded',
          lineHeight: '40px',
          color: 'rgba(34, 34, 34, 1)'
        },
        h2: {
          fontSize: 30,
          fontWeight: 600,
          fontFamily: 'Unbounded',
          lineHeight: '45px',
          color: 'rgba(34, 34, 34, 1)'
        },
        h3: {
          fontFamily: 'Unbounded',
          fontSize: 21,
          fontWeight: 600,
          lineHeight: '30px',
          color: 'rgba(34, 34, 34, 1)'
        },
        body1: {
          fontSize: 18,
          fontWeight: 400,
          lineHeight: '28px',
          color: 'rgba(34, 34, 34, 1)'
        },
        body2: {
          fontSize: 16,
          fontWeight: 400,
          lineHeight: '24px',
          color: 'rgba(34, 34, 34, 1)'
        },
        subtitle1: {
          fontSize: 18,
          fontWeight: 500,
          lineHeight: '27px',
          color: 'rgba(34, 34, 34, 1)'
        },
        subtitle2: {
          fontSize: 16,
          fontWeight: 500,
          lineHeight: '24px',
          color: 'rgba(34, 34, 34, 1)'
        },
        caption: {
          fontSize: 14,
          fontWeight: 400,
          lineHeight: '21px',
          color: 'rgba(34, 34, 34, 1)',
          marginBottom: 5
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: 'Rubik-Regular',
          fontSize: 14,
          lineHeight: '21px'
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          fontFamily: 'Rubik-Regular',
          fontSize: 16
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: 'Rubik-Regular',
          fontSize: '18px',
          fontWeight: 400,
          lineHeight: '32px',
          textAlign: 'left',
          border: '1px solid rgba(121, 201, 18, 0.2)',
          color: 'rgba(34, 34, 34, 1)',
          backgroundColor: '#fff',
          borderRadius: 0,
          '&.Mui-error': {
            border: '1px solid #EE0004'
          },
          '@media (max-width: 900px)': {
            fontSize: '16px'
          }
        },
        notchedOutline: {
          border: 'none'
        },
        input: {
          padding: '10.07px 14px'
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: 'Rubik-Regular',
          fontSize: 14,
          lineHeight: '14px',
          marginLeft: 0,
          marginTop: 5
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          minWidth: 200,
          maxWidth: 500,
          backgroundColor: 'rgba(255, 255, 255, 1)',
          padding: 20
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Rubik-Regular'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginTop: 0,
          fontFamily: 'Rubik-Regular'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontFamily: 'Rubik-Regular'
        }
      }
    }
  }
});

export default theme;
