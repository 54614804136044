class Message {
  constructor(message, variant = 'error', icon, details) {
    this.message = message;
    this.variant = variant;
    this.icon = icon;
    this.details = details;
  }
}

export default Message;
