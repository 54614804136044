import qs from 'qs';

const initialState = {
  state: {},
  vacancies: {},
  similarProfessions: {}
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PROFESSION_INFO_SUCCESS':
      const id = action?.payload?._id;
      return { ...state, state: { ...state.state, [id]: action.payload } };
    case 'GET_VACANCIES_SUCCESS':
      const similarProfessionsUrl = action.url;
      const vacanciesId = similarProfessionsUrl.split('/').pop();
      return { ...state, vacancies: { ...state.vacancies, [vacanciesId]: action.payload } };
    case 'GET_SIMILAR_PROFESSIONS_SUCCESS':
      const filters = qs.parse(action.url)?.filters?.tag_ids;
      const vacanciesUrl = qs.stringify(filters);
      return {
        ...state,
        similarProfessions: { ...state.similarProfessions, [vacanciesUrl]: action.payload }
      };
    case 'GET_SKILLS_LIST_SUCCESS':
      return { ...state, skills: action.payload };
    case 'GET_CATEGORIES_SUCCESS':
      return { ...state, categories: action.payload };
    case 'GET_TAGS_LIST_SUCCESS':
      return { ...state, tags: action.payload };
    case 'GET_EDUCATION_PROGRAM_INFO_SUCCESS':
      return { ...state, programs: action.payload };
    case 'DATA_LIST/REVIEWS/GET_LIST_SUCCESS':
    case 'DATA_LIST/QUESTIONS/GET_LIST_SUCCESS':
      const anchor = (window.location.hash || '').replace('#', '');
      if (anchor) {
        setTimeout(() => {
          const element = document.getElementById(anchor);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 250);
      }
      return { ...state };
    default:
      return state;
  }
};

export default rootReducer;
