import { ReactComponent as AmbassadorIcon } from 'assets/icons/daily/achievement/badge-ambassador.svg';
import { ReactComponent as CandidateIcon } from 'assets/icons/daily/achievement/badge-candidate.svg';
import { ReactComponent as CommenterIcon } from 'assets/icons/daily/achievement/badge-commenter.svg';
import { ReactComponent as LearnerIcon } from 'assets/icons/daily/achievement/badge-learner.svg';
import { ReactComponent as ReaderfIcon } from 'assets/icons/daily/achievement/badge-readerf.svg';
import { ReactComponent as WatcherIcon } from 'assets/icons/daily/achievement/badge-watcher.svg';
import { ReactComponent as CoinIcon } from 'assets/icons/daily/coin.svg';

const getAchievementIcon = (achievement) => {
  switch (achievement) {
    case 'ambassador':
      return <AmbassadorIcon />;
    case 'candidate':
      return <CandidateIcon />;
    case 'commenter':
      return <CommenterIcon />;
    case 'newby':
      return <LearnerIcon />;
    case 'reader':
      return <ReaderfIcon />;
    case 'viewer':
      return <WatcherIcon />;
    case 'referFriend':
      return <CoinIcon />;
    default:
      return null;
  }
};

export { getAchievementIcon };
