import { combineReducers } from '@reduxjs/toolkit';
import { routerReducer } from 'react-router-redux';
import authReducer from 'reducers/app';
import dataTableReducer from 'services/dataList/reducer';
import professionEndPoint from 'endPoints/professions';
import errorReducer from 'reducers/error';
import professionReducer from 'reducers/profession';
import articlesReducer from 'reducers/articles';
import pagesReducer from 'reducers/pages';
import companyReducer from 'reducers/company';
import consultantsReducer from 'reducers/consultants';
import mentorsReducer from 'reducers/mentors';
import gamificationReducer from 'reducers/gamification';

const reducers = combineReducers({
  app: authReducer,
  router: routerReducer,
  error: errorReducer,
  profession: professionReducer,
  articles: articlesReducer,
  pages: pagesReducer,
  company: companyReducer,
  consultants: consultantsReducer,
  mentors: mentorsReducer,
  gamification: gamificationReducer,
  [professionEndPoint.sourceName]: dataTableReducer(professionEndPoint)
});

export default reducers;
